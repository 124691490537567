// FlipCard.js
import React, { useState, useEffect, useRef } from 'react';

const FlipCard = ({ value, label }) => {
  const [currentValue, setCurrentValue] = useState(('0' + value).slice(-2));
  const prevValueRef = useRef(currentValue);
  const topHalfRef = useRef(null);
  const bottomHalfRef = useRef(null);

  const flip = (newValue) => {
    const topFlip = document.createElement('div');
    const bottomFlip = document.createElement('div');
    
    topFlip.className = 'top-flip';
    bottomFlip.className = 'bottom-flip';

    topFlip.innerText = prevValueRef.current;
    bottomFlip.innerText = newValue;

    topFlip.addEventListener('animationstart', () => {
      topHalfRef.current.innerText = newValue;
    });

    topFlip.addEventListener('animationend', () => {
      topFlip.remove();
    });

    bottomFlip.addEventListener('animationend', () => {
      bottomHalfRef.current.innerText = newValue;
      bottomFlip.remove();
    });

    bottomHalfRef.current.after(topFlip, bottomFlip);
  };

  useEffect(() => {
    const newValue = ('0' + value).slice(-2);
    if (currentValue !== newValue) {
      flip(newValue);
      prevValueRef.current = newValue;
      setCurrentValue(newValue);
    }
  }, [value]);

  return (
    <div className="card">
      <div className="flip-card">
        <div ref={topHalfRef} className="top-half">{currentValue}</div>
        <div ref={bottomHalfRef} className="bottom-half">{currentValue}</div>
      </div>
      <p>{label}</p>
    </div>
  );
};

export default FlipCard;
