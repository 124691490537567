import React from "react";
import { Modal } from "react-bootstrap";

const TechRequirementsModal = ({ show, setShow }) => {
  return (
    <Modal show={show} onHide={() => setShow(!show)}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">
          Tech Requirements and Browser Guide
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul className="list_styled">
          <li>
            A computer with a high-speed internet connection (DSL or cable
            recommended) and speakers is required.
          </li>
          <li>
            Compatible browsers:
            <ul className="list_disc">
              <li>Google Chrome 50 or higher</li>
              <li>Microsoft Edge</li>
              <li>Internet Explorer 10 or higher</li>
              <li>Firefox</li>
              <li>Safari</li>
              <li>Opera</li>
            </ul>
          </li>
          <li>
            For video issues (buffering or no sound):
            <ul className="list_disc">
              <li>Confirm your internet connection is stable.</li>
              <li>
                Check bandwidth sufficiency; use speedtest.net if necessary.
              </li>
              <li>
                Minimum internet speed: 1 Mbps with a 1:1 upload to download
                ratio.
              </li>
            </ul>
          </li>
          <li>
            Enable third-party cookies in your browser for full functionality.
          </li>
          <li>A headset can be used as an alternative to speakers.</li>
          <li>To refresh your browser on a laptop/desktop, press CTRL+F5.</li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default TechRequirementsModal;
