
const CustomHead = ({ title, description,logo }) => {
  // console.log(logo);
  return (
    <head>
      <link rel="icon" href={logo} />
      <title>{title}</title>
      <meta name="description" content={description} />
      
    </head>
  );
};

export default CustomHead;