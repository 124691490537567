import { useAppContext } from "context/AppContext";
import React from "react";

const PageLoader = () => {
  const { data } = useAppContext();
console.log("loader")
  return (
    <div className="page_loader">
      <span class="loader" style={{ borderTop: data ? `3px solid ${data.p_color}` : "3px solid #000" }}
      ></span>
    </div>
  );
};

export default PageLoader;
