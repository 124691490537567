import React from "react";

const Footer = () => {
  return (
    <footer>
      <div class="container">
        <div class="footer_body">
          <p class="m-0">
            Copyright ©2024 Accurate Securities & Registry Private Limited.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
