import React from "react";
import { Modal } from "react-bootstrap";

const ConnectUsModal = ({ show, setShow,data }) => {
  return (
    <Modal show={show} onHide={() => setShow(!show)}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">Connect with Us</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <form className="row g-4">
                  <div className="col-sm-12 col-lg-12">
                    <div className="contact-info address">
                      <h6>Regd. Office</h6>
                      <div className="info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          className="feather feather-map-pin"
                          // color="#fe6c35"
                          style={{
                            color:
                              data && data.p_color !== ""
                                ? data.p_color
                                : "#fe6c35",
                          }}
                        >
                          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                          <circle cx="12" cy="10" r="3"></circle>
                        </svg>
                        <span className="ms-3">{data && data.address}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-12">
                    <div className="contact-info number">
                      <h6>Phone Number</h6>
                      <div className="info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          className="feather feather-phone"
                          style={{
                            color:
                              data && data.p_color !== ""
                                ? data.p_color
                                : "#fe6c35",
                          }}
                        >
                          <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                        </svg>
                        <span className="ms-3">{data && data.phone}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-12">
                    <div className="contact-info email">
                      <h6>Email Address</h6>
                      <div className="info">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          stroke-linecap="round"
                          strokeLinejoin="round"
                          className="feather feather-mail"
                          style={{
                            color:
                              data && data.p_color !== ""
                                ? data.p_color
                                : "#fe6c35",
                          }}
                        >
                          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                          <polyline points="22,6 12,13 2,6"></polyline>
                        </svg>
                        <span className="ms-3">{data && data.email}</span>
                      </div>
                    </div>
                  </div>
                  {data && data.website !== "" && (
                    <div className="col-sm-12 col-lg-12">
                      <div className="contact-info email">
                        <h6>Website</h6>
                        <div className="info">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            stroke-linecap="round"
                            strokeLinejoin="round"
                            class="feather feather-globe"
                            style={{
                              color:
                                data && data.p_color !== ""
                                  ? data.p_color
                                  : "#fe6c35",
                            }}
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="2" y1="12" x2="22" y2="12"></line>
                            <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                          </svg>
                          <span className="ms-3">
                            <a
                              href={data && data.website !== "" && data.website}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-black text-decoration-underline"
                            >
                              {data &&
                                data.website !== "" &&
                                new URL(data.website).hostname}
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
      </Modal.Body>
    </Modal>
  );
};

export default ConnectUsModal;
