import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FlipCard from "./FlipCard";

const calculateEvotingTimeLeft = (targetDate) => {
  const now = new Date();
  const difference = targetDate - now;
  let timeLeft = {};
  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
  // console.log(timeLeft)
  return timeLeft;
};

const EVoting = ({
  countdownDate,
  daysLabel,
  hoursLabel,
  minutesLabel,
  secondsLabel,
  onCountdownComplete,
}) => {
  const [etimeLeft, setETimeLeft] = useState(
    calculateEvotingTimeLeft(countdownDate)
  );
  useEffect(() => {
    // console.log("countdownDate",countdownDate)
    const timer = setTimeout(() => {
      const updatedTimeLeft = calculateEvotingTimeLeft(countdownDate);
      setETimeLeft(updatedTimeLeft);

      if (
        updatedTimeLeft.days === 0 &&
        updatedTimeLeft.hours === 0 &&
        updatedTimeLeft.minutes === 0 &&
        updatedTimeLeft.seconds === 0
      ) {
        if (onCountdownComplete) {
          onCountdownComplete();
        }
      }
    }, 500);

    return () => clearTimeout(timer);
  });

  return (
    <main className="m-0 p-0">
      <div className="cards">
        <FlipCard value={etimeLeft.days || 0} label={daysLabel || "Days"} />
        <FlipCard value={etimeLeft.hours || 0} label={hoursLabel || "Hours"} />
        <FlipCard
          value={etimeLeft.minutes || 0}
          label={minutesLabel || "Minutes"}
        />
        <FlipCard
          value={etimeLeft.seconds || 0}
          label={secondsLabel || "Seconds"}
        />
      </div>
    </main>
  );
};

EVoting.propTypes = {
  countdownDate: PropTypes.instanceOf(Date).isRequired,
  daysLabel: PropTypes.string,
  hoursLabel: PropTypes.string,
  minutesLabel: PropTypes.string,
  secondsLabel: PropTypes.string,
  onCountdownComplete: PropTypes.func,
};

export default EVoting;
