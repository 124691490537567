import React from "react";
import { Modal } from "react-bootstrap";

const LiveChatModal = ({ show, setShow, data, formData, handleInputChange, handleFormSubmit }) => {

  return (
    <Modal show={show} onHide={() => setShow(!show)}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">Live Chat</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Please note that in case, if you will provide incorrect Demat/ Ledger
          Folio Number, then your question/ suggestion will not be addressed.
        </p>
        <form class="row g-4">
          <div className="col-sm-12 col-lg-6">
            <div className="form-group">
              <input
                type="text"
                name="name"
                id=""
                placeholder="Name"
                value={formData.name}
                onChange={(e) => handleInputChange(e)}
                required
              />
            </div>
          </div>
          <div class="col-sm-12 col-lg-6">
            <div class="form-group">
              <input
                type="text"
                name="lf"
                id=""
                placeholder="Demat/ Ledger Folio Number"
                required
                value={formData.lf}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
          <div class="col-sm-12 col-lg-12">
            <div class="form-group">
              <textarea
                name="question"
                id=""
                cols="30"
                rows="10"
                placeholder="Message"
                value={formData.question}
                onChange={(e) => handleInputChange(e)}
              ></textarea>
            </div>
          </div>
          <div class="col-sm-12 col-lg-12">
            <button
              type="submit"
              className="btn btn-primary"
              style={
                data && data.p_color !== ""
                  ? {
                      background: data && data.p_color,
                      borderColor: data && data.p_color,
                    }
                  : {}
              }
              onClick={(e) => handleFormSubmit(e)}
            >
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default LiveChatModal;
