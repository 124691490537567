// AppContext.js
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';


const AppContext = createContext();
export const useAppContext = () => {
    return useContext(AppContext);
};

export const AppContextProvider = ({ children }) => {
    const [state, setState] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [timerOfMeeting, setTimerOfMeeting] = useState("");
    const [page, setPage] = useState("");

    useEffect(() => {
        // console.log("pageNumber", page);
        // Rest of your useEffect logic
    }, [page]);
    // useEffect(() => {
    //     const fetchData = async () => {
    //         setLoading(true);
    //         try {
    //             const response = await axios.get(`https://cowebcast.com/beta/api/apis/viewOrg.php?id=${page}`, {
                    
    //             });
    //             if (response.status === 200) {
    //                 const json = response.data;
    //                 console.log("res", json[0]);
    //                 setData(json[0]);
    //             }
    //         } catch (e) {
    //             console.log(e);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };
    
    //     if (page !== "") {
    //         fetchData();
    //     }
    // }, [page]);
    // useEffect(() => {
      const clearCacheData = async () => {
        try {
          console.log("clear cache fun")
          const cacheNames = await caches.keys();
          cacheNames.forEach(async (name) => {
            console.log("NameofCache:", name);
            await caches.delete(name);
          });
            // Clearing sessionStorage
            window.sessionStorage.clear();
            console.log("Session storage cleared");
        } catch (error) {
          console.error("Error clearing cache data:", error);
        }
      };
  
      // clearCacheData();
    // }, []);
    
    
    const handleSubmitQuestions = async (data) => {
        // Create a request body object
        const requestBody = {
            "vc_id": data.vc_id,
            "name": data.name,
            "lf": data.lf,
            "question": data.question
        };
        try {
            const response = await fetch('https://cowebcast.com/beta/api/apis/postQa.php', {
            method: 'POST',
            body: JSON.stringify(requestBody), 
        });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          } 
          // Handle the response as needed
          const data = await response.json();
        //   console.log('Response data:', data);
        } catch (error) {
          console.error('Error:', error);
        }
      };
    return (
        <AppContext.Provider value={{ state, setState, loading, data,clearCacheData, setPage,handleSubmitQuestions,setLoading,setData,page }}>
            {children}
        </AppContext.Provider>
    );
};
