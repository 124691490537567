import Countdown from "Components/Contdown/Countdown";
import Footer from "Components/Footer";
import PageLoader from "Components/PageLoader/PageLoader";
import Plyr from "plyr";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "Styles/castOne.module.css";
import { useAppContext } from '../context/AppContext';
import EntryModal from "Components/EntryModal/EntryModal";
import CustomHead from "header/CustomHead";
const CastOne = () => {
  const {
    //   setPage,
    //   loading,
    //   data,
    clearCacheData,
    handleSubmitQuestions,
    //   setLoading,
    //   setData,
    //   page,
  } = useAppContext();
  const { pageNumber } = useParams();
  const [videoShow, setVideoShow] = useState(false);
  const [data, setData] = useState(null);
  const [page, setPage] = useState("");
  const [dateOfMeeting, setDateOfMeeting] = useState("");
  const [loading, setLoading] = useState(false);

  const [timerOfMeeting, setTimerOfMeeting] = useState("");
  const [formData, setFormData] = useState({
    vc_id: '',
    name: '',
    lf: '',
    question: '',
  });
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Check if any field in formData is empty
    if (formData.vc_id === '' ||
      formData.name === '' ||
      formData.lf === '' ||
      formData.question === '') {
      // Display an error message or handle the validation error as needed
      alert('Please fill in all fields.');
    } else {
      // All fields are filled, submit the form
      handleSubmitQuestions(formData);
      alert('Thanks for question!!!!');
      setFormData({
        vc_id: '',
        name: '',
        lf: '',
        question: '',
      })
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log({name, value});
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    setPage(pageNumber)
    formData.vc_id = pageNumber;
  }, [pageNumber])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://cowebcast.com/beta/api/apis/viewOrg.php?id=${pageNumber}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        if (response.status === 200) {
          const json = await response.json();
          console.log(json[0]);
          setData(json[0]);
        }
      } catch (e) {
        // setError(e);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (data !== null) {
      // console.log(data.date);
      const inputDate = new Date(data.date);
      const options = { year: "numeric", month: "long", day: "numeric" };
      const formattedDate = inputDate.toLocaleDateString(undefined, options);
      setDateOfMeeting(formattedDate);
      const dateString = data.date; // "2023-11-02"
      const timeString = data.time; // "10:00:00 AM"
      // Combine date and time strings into a single string
      const dateTimeString = dateString + " " + timeString;
      // Create a new Date object using the combined date and time string
      const targetDate = new Date(dateTimeString).getTime();
      setTimerOfMeeting(targetDate)
      // console.log(targetDate);
      const currentDate = new Date();
      const currentDateTimestamp = currentDate.getTime();
      // console.log("currentDateTimestamp > targetDate", currentDateTimestamp > targetDate);
      if (currentDateTimestamp > targetDate) {
        setVideoShow(true)
      }
    }
  }, [data])

  useEffect(() => {
    if (data !== null && videoShow === true && data.m_type === "bgMedia") {
      const player = new Plyr("#player");
      window.player = player;
      function on(selector, type, callback) {
        document
          .querySelector(selector)
          .addEventListener(type, callback, false);
      }
      on(".js-play", "click", () => {
        player.play();
      });
      on(".js-pause", "click", () => {
        player.pause();
      });
      on(".js-stop", "click", () => {
        player.stop();
      });
      on(".js-rewind", "click", () => {
        player.rewind();
      });
      on(".js-forward", "click", () => {
        player.forward();
      });
      return () => {
        if (player) {
          player.destroy();
        }
      }
    }
  }, [data, videoShow])

  const [modalShow, setModalShow] = useState(false);
  // useEffect(() => {
  //   setModalShow(true);
  // }, []);
  const [time, setTime] = useState('');
  useEffect(() => {
 if(data&&data.time!==""){ 
  const [hours, minutes] = data.time.split(':').map(Number);

  // Convert the time to 12-hour format
  let period = 'AM';
  let twelveHourFormatHours = hours;
  if (hours >= 12) {
      period = 'PM';
      twelveHourFormatHours = hours === 12 ? 12 : hours - 12;
  }
  
  // Format the time in 12-hour format
  const formattedTime = `${twelveHourFormatHours}:${String(minutes).padStart(2, '0')} ${period}`;
  // console.log("Time in 12-hour format:", formattedTime);
  setTime(formattedTime)
 }
  }, [data]);

  if (loading) {
    return <PageLoader />;
  }
  return (
    <React.Fragment>
      {!modalShow && (
        <>
          <header
            class={styles.header}
            style={{
              backgroundImage: "url('/assets/images/banner/banner-1.webp')",
            }}
          >
            <div class="container">
              <div class={styles.intro}>
                {data?.is_logo === "Yes" &&
                  <div class={styles.logo}>
                    <img
                      src={data?.logo}
                      alt="logo"
                      class="img-fluid"
                    />
                  </div>}
                <h3>{data && data.tagline}</h3>
                <h2 class="fw-bold"  >{data && data.org}</h2>
                <p> Date: {dateOfMeeting}, {data && time} IST</p>
              </div>
            </div>
          </header>
          <section className={`${styles.section} ${styles.pt_50}`}>
            <div class="container">
              <div class="row">
                <div class="col-md-12 col-lg-12 mx-auto">
                  <div class="row g-4">
                    {data && <Countdown countdownDate={timerOfMeeting} />}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className={styles.section}>
            <div class="container">
              <div class="row">
                <div class="col-11 mx-auto">
                  <div className="video_wrapper plyr__video-embed" id="player">
                    {data && videoShow === true ?
                      (
                        <>
                          <iframe
                            src={`https://www.youtube.com/embed/${data.stream}?autoplay=0&controls=0&disablekb=1&playsinline=1&cc_load_policy=0&cc_lang_pref=auto&widget_referrer=https%3A%2F%2Fcowebcast.com%2Fmeet%2FODA0%2FINE075K01013&rel=0&showinfo=0&iv_load_policy=3&modestbranding=1&customControls=true&noCookie=false&enablejsapi=1&origin=https%3A%2F%2Fcowebcast.com&widgetid=1`}
                            allowFullScreen
                            allowTransparency
                            allow="autoplay"
                            title="Video Player"
                          ></iframe>
                          <button className="js-play" style={{ display: "none" }}>
                            Play
                          </button>
                          <button className="js-pause" style={{ display: "none" }}>
                            Pause
                          </button>
                          <button className="js-stop" style={{ display: "none" }}>
                            Stop
                          </button>
                          <button className="js-rewind" style={{ display: "none" }}>
                            Rewind
                          </button>
                          <button className="js-forward" style={{ display: "none" }}>
                            Forward
                          </button>
                        </>
                      ) : (
                        data && data.m_type === "bgMedia" ?
                          (
                            <>
                              <iframe
                                src={`${data.bg}`}
                                allowFullScreen
                                allowTransparency
                                allow="autoplay; encrypted-media"
                                title="Video Player"
                              ></iframe>
                              <button
                                className="js-play"
                                style={{ display: "none" }}
                                onClick={() => {
                                  const iframe = document.querySelector(".video_wrapper iframe");
                                  iframe && iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                                }}
                              >
                                Play
                              </button>
                              <button className="js-pause" style={{ display: "none" }}>
                                Pause
                              </button>
                              <button className="js-stop" style={{ display: "none" }}>
                                Stop
                              </button>
                              <button className="js-rewind" style={{ display: "none" }}>
                                Rewind
                              </button>
                              <button className="js-forward" style={{ display: "none" }}>
                                Forward
                              </button>
                            </>
                          ) : (
                            <div className="col-12 mx-auto">
                              {data && data.banner && (
                                <img src={data.banner} alt="banar" className="img-fluid img-full" />
                              )}
                            </div>
                          )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {data?.is_bod === "Yes" &&
          <>
          <section className={styles.section}>
              <div class="container">
                <div class={styles.section_title}>
                  <div class="row gy-4">
                    <div class="col-md-12 col-lg-6">
                      <h5 class={styles.title__has_bottom}>
                        Board of Directors & KMPs.
                      </h5>
                    </div>
                    <div class="col-md-12 col-lg-6">
                      <h2 class={styles.title__big_text}>
                        Our Organization's Pillars!
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="row g-4">
                  <div class="col-sm-12-col-md-6 col-lg-4">
                    <div class={styles.director_card}>
                      <div class={styles.card_image}>
                        <img
                          src="/assets/images/directors/HarshadPatel-ManagingDirector.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class={styles.card_details}>
                        <h5>
                          <a href="/" target="_blank" rel="noopener noreferrer">
                          Mr.Harshad Patel
                          </a>
                        </h5>
                        <h6>Managing Director</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12-col-md-6 col-lg-4">
                    <div class={styles.director_card}>
                      <div class={styles.card_image}>
                        <img
                          src="/assets/images/directors/GaneshAgrawal-ChiefFinancialOfficer.jpg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class={styles.card_details}>
                        <h5>
                          <a href="/" target="_blank" rel="noopener noreferrer">
                          Mr. Ganesh Agrawal	
                          </a>
                        </h5>
                        <h6>Chief Financial Officer</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12-col-md-6 col-lg-4">
                    <div class={styles.director_card}>
                      <div class={styles.card_image}>
                        <img
                          src="/assets/images/directors/DineshMehta-Director.jpeg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class={styles.card_details}>
                        <h5>
                          <a href="/" target="_blank" rel="noopener noreferrer">
                          Mr. Dinesh Mehta
                          </a>
                        </h5>
                        <h6>Executive Director</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row g-4">
                  <div class="col-sm-12-col-md-6 col-lg-4">
                    <div class={styles.director_card}>
                      <div class={styles.card_image}>
                        <img
                          src="/assets/images/directors/SheelaAyyar-Director.jpg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class={styles.card_details}>
                        <h5>
                          <a href="/" target="_blank" rel="noopener noreferrer">
                          Mrs. Sheela Ayyar
                          </a>
                        </h5>
                        <h6>Director</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12-col-md-6 col-lg-4">
                    <div class={styles.director_card}>
                      <div class={styles.card_image}>
                        <img
                          src="/assets/images/directors/KirtiRathod-Director.jpg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class={styles.card_details}>
                        <h5>
                          <a href="/" target="_blank" rel="noopener noreferrer">
                          Mr. Kirti Rathod
                          </a>
                        </h5>
                        <h6>Director</h6>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12-col-md-6 col-lg-4">
                    <div class={styles.director_card}>
                      <div class={styles.card_image}>
                        <img
                          src="/assets/images/directors/MaheshSolanki-Director.jpg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                      <div class={styles.card_details}>
                        <h5>
                          <a href="/" target="_blank" rel="noopener noreferrer">
                          Mr. Mahesh Solanki
                          </a>
                        </h5>
                        <h6>Director</h6>
                      </div>
                    </div>
                  </div>
                </div>
               
              </div>
            </section>
          </>
           }
          {data?.is_company === "Yes" &&
            <section className={styles.section}>
              <div class="container">
                <div class="row gy-5">
                  <div class="col-md-12 col-lg-8">
                    <div class={styles.section_title}>
                      <div class="row gy-4">
                        <div class="col-md-12 col-lg-12">
                          <h5 class={styles.title__has_bottom}>
                            About the Organization.
                          </h5>
                          <span className={styles.title_bottom} style={{ background: data?.p_color }}></span>
                        </div>
                      </div>
                    </div>
                    <p className="m-0">
                      {data && data.about}
                    </p>
                  </div>
                  <div class="col-md-12 col-lg-4">
                    <div class={styles.section_title}>
                      <div class="row gy-4">
                        <div class="col-md-12 col-lg-12">
                          <h5 class={styles.title__has_bottom}>Important Links.</h5>
                          <span className={styles.title_bottom} style={{ background: data?.p_color }}></span>
                        </div>
                      </div>
                    </div>
                    <div class="row gy-4">
                      <div class="col-12">
                        <div class={styles.link_box}>
                          <div class={styles.icon}>
                            <img
                              src="/assets/images/icons/link.png"
                              alt="icon"
                              class="img-fluid"
                            />
                          </div>
                          <div class="text">
                            <p class="m-0" style={{ color: data?.p_color }}>
                              Link <i class="fa-solid fa-angle-right"></i>
                            </p>
                            <a href={data?.website} target="_blank" rel="noopener noreferrer">
                              Organization Website</a>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class={styles.link_box}>
                          <div class={styles.icon}>
                            <img
                              src="/assets/images/icons/link.png"
                              alt="icon"
                              class="img-fluid"
                            />
                          </div>
                          <div class="text">
                            <p class="m-0" style={{ color: data?.p_color }}>
                              Link
                              <i class="fa-solid fa-angle-right" ></i>
                            </p>
                            <a href={data?.notice} target="_blank" rel="noopener noreferrer">
                              Company Notice
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>}

          <section className={styles.section}>
            <div class="container">
              <div class="row gy-5">
                {data?.is_contact === "Yes" &&
                  <div class="col-md-12 col-lg-6">
                    <div class={styles.section_title}>
                      <div class="row gy-4">
                        <div class="col-md-12 col-lg-12">
                          <h5 class={styles.title__has_bottom}>Contact Us.</h5>
                          <span className={styles.title_bottom} style={{ background: data?.p_color }}></span>
                        </div>
                      </div>
                    </div>
                    <p>
                      In case of any query related to attending Meeting, reach to us
                      using any of the below mentioned contact medium!
                    </p>
                    <div class="row gy-4 mt-4">
                      <div class="col-12">
                        <h6 class="fw-bold fs-18">Regd. Office</h6>
                        <div class="mt-3 d-flex align-items-center gap-3">
                          <div class="icon-sm" style={{ color: data?.p_color }}>
                            <i class="fa-solid fa-location-dot" ></i>
                          </div>
                          <span>
                            {data?.address}
                          </span>
                        </div>
                      </div>
                      <div class="col-12">
                        <h6 class="fw-bold fs-18">Phone Number</h6>
                        <div class="mt-3 d-flex align-items-center gap-3">
                          <div class="icon-sm" style={{ color: data?.p_color }}>
                            <i class="fa-solid fa-phone"></i>
                          </div>
                          <span>{data?.phone} </span>
                        </div>
                      </div>
                      <div class="col-12">
                        <h6 class="fw-bold fs-18">Email Address</h6>
                        <div class="mt-3 d-flex align-items-center gap-3">
                          <div class="icon-sm" style={{ color: data?.p_color }}>
                            <i class="fa-solid fa-envelope"></i>
                          </div>
                          <span>{data?.email} </span>
                        </div>
                      </div>
                    </div>
                  </div>}
                {data?.is_chat === "Yes" && <div class="col-md-12 col-lg-6">
                  <div class={styles.section_title}>
                    <div class="row gy-4">
                      <div class="col-md-12 col-lg-12">
                        <h5 class={styles.title__has_bottom}>Ask Questions.</h5>
                      </div>
                    </div>
                  </div>
                  <p>
                    Please note that in case, if you will provide incorrect Demat/
                    Ledger Folio Number, then your question/ suggestion will not be
                    addressed.
                  </p>
                  <form class="row g-4">
                    <div class="col-sm-12 col-lg-6">
                      <div class="form-group">
                        <input
                          type="text"
                          name="name"
                          id=""
                          placeholder="Name"
                          value={formData.name}
                          onChange={(e) => handleInputChange(e)}
                          required
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-6">
                      <div class="form-group">
                        <input
                          type="text"
                          name="lf"
                          id=""
                          placeholder="Demat/ Ledger Folio Number"
                          required
                          value={formData.lf}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-12">
                      <div class="form-group">
                        <textarea
                          name="question"
                          id=""
                          cols="30"
                          rows="10"
                          placeholder="Message"
                          value={formData.question}
                          onChange={(e) => handleInputChange(e)}
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-12">
                      <button type="submit" class="btn btn-primary" style={
                        data && data.p_color !== ""
                          ? {
                            background: data && data.p_color,
                            borderColor: data && data.p_color,
                          }
                          : {}

                      }
                        onClick={(e) => handleFormSubmit(e)}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>}
              </div>
            </div>
          </section>
          <section className={styles.section}>
            <div class="container">
              <div class="text-center">
                Note: This web page will be taken down within 24 hours of completion
                of Meeting.
              </div>
            </div>
          </section>
          <Footer />
        </>
      )}
      <EntryModal show={modalShow} setShow={setModalShow} data={data} />
      <CustomHead title={data && data.org} description={"Accurate Securities"}  logo={"%PUBLIC_URL%/fevicon.png"}/>

    </React.Fragment>
  );
};

export default CastOne;
