import React from "react";
import { Modal } from "react-bootstrap";

const OrganizationInformationModal = ({ show, setShow, data }) => {
  return (
    <Modal show={show} onHide={() => setShow(!show)}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">Organization Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{data && data.about}</p>
      </Modal.Body>
    </Modal>
  );
};

export default OrganizationInformationModal;
