import { useAppContext } from 'context/AppContext';
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';

const EntryModal = ({ show, setShow, data }) => {
    const { pageNumber, id} = useParams();
    
    const navigate = useNavigate();
  const {  handleSubmitQuestions } = useAppContext();

    const handleSubmit = (e) => {
        e.preventDefault();
       
    }
    const [formData, setFormData] = useState({
        vc_id: '',
        name: '',
        lf: '',
        question: '',
      });
      useEffect(() => {
        formData.vc_id = pageNumber;
      }, [pageNumber,]) 
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        // console.log({name, value});
        setFormData({ ...formData, [name]: value });
      };
      const handleFormSubmit = (e) => {
        e.preventDefault();
        // Check if any field in formData is empty
        if (formData.vc_id === '' ||
          formData.name === '' ||
          formData.lf === '') {
          // Display an error message or handle the validation error as needed
          alert('Please fill in all fields.');
        } else {
          // All fields are filled, submit the form
          handleSubmitQuestions(formData);
          alert('Thanks for question!!!!');
          setFormData({
            vc_id: '',
            name: '',
            lf: '',
            question: '',
          })
            if (id === "2") {
                // console.log(id);
              // Assuming you want to navigate to the '/2/pageNumber' route
              navigate(`/2/${pageNumber}`);
            }
        }
      };
    return (
        <Modal backdrop="static" centered show={show} onHide={() => setShow(false)} className='entryModal'>
            <Modal.Header>Enter Your Details</Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <Row className='gy-4'>
                        <Col xs={12}>
                            <div className="form-group">
                                <label htmlFor="">Enter Your Name</label>
                                <input type="text"
                                  name="name"
                                  id=""
                                  placeholder="Name"
                                  value={formData.name}
                                  onChange={(e) => handleInputChange(e)}
                                  required
                                 />
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="form-group">
                                <label htmlFor="">Enter Your Number</label>
                                <input type="text" name="lf" id="lf"
                                 value={formData.lf}
                        onChange={(e) => handleInputChange(e)}/>
                            </div>
                        </Col>
                        <Col xs={12} className='text-center'>
                            <button type='submit' className='btn btn-primary' style={
                                data && data.p_color !== ""
                                    ? {
                                        background: data && data.p_color,
                                        borderColor: data && data.p_color,
                                    }
                                    : {}}
                                    onClick={(e) => handleFormSubmit(e)}
                                    >Submit</button>
                        </Col>
                    </Row>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default EntryModal