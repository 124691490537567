import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FlipCard from "./FlipCard";

const calculateTimeLeft = (targetDate, timeOffset) => {
  // Adjust the current time using the time offset
  const now = new Date().getTime() + timeOffset;
  const difference = new Date(targetDate).getTime() - now;

  let timeLeft = {};
  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  } else {
    // If the countdown is over, set all units to zero
    timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }
  return timeLeft;
};

const Countdown = ({
  countdownDate,
  serverTime,
  daysLabel,
  hoursLabel,
  minutesLabel,
  secondsLabel,
  onCountdownComplete,
}) => {
  // State to hold the time offset between server and client
  const [timeOffset, setTimeOffset] = useState(0);

  // Calculate the time offset once when the component mounts
  useEffect(() => {
    const localTime = new Date().getTime();
    const serverTimeMs = new Date(serverTime).getTime();
    setTimeOffset(serverTimeMs - localTime);
  }, [serverTime]);

  // State to hold the time left for the countdown
  const [timeLeft, setTimeLeft] = useState(
    calculateTimeLeft(countdownDate, timeOffset)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      const updatedTimeLeft = calculateTimeLeft(countdownDate, timeOffset);
      setTimeLeft(updatedTimeLeft);

      // Check if the countdown is complete
      if (
        updatedTimeLeft.days === 0 &&
        updatedTimeLeft.hours === 0 &&
        updatedTimeLeft.minutes === 0 &&
        updatedTimeLeft.seconds === 0
      ) {
        clearInterval(timer); // Stop the timer
        if (onCountdownComplete) {
          onCountdownComplete();
        }
      }
    }, 1000);

    // Cleanup the timer when the component unmounts
    return () => clearInterval(timer);
  }, [countdownDate, timeOffset, onCountdownComplete]);

  return (
    <main className="m-0 p-0">
      <div className="cards">
        <FlipCard value={timeLeft.days} label={daysLabel || "Days"} />
        <FlipCard value={timeLeft.hours} label={hoursLabel || "Hours"} />
        <FlipCard value={timeLeft.minutes} label={minutesLabel || "Minutes"} />
        <FlipCard value={timeLeft.seconds} label={secondsLabel || "Seconds"} />
      </div>
    </main>
  );
};

Countdown.propTypes = {
  countdownDate: PropTypes.instanceOf(Date).isRequired,
  serverTime: PropTypes.instanceOf(Date).isRequired, // Ensure serverTime is provided
  daysLabel: PropTypes.string,
  hoursLabel: PropTypes.string,
  minutesLabel: PropTypes.string,
  secondsLabel: PropTypes.string,
  onCountdownComplete: PropTypes.func,
};

export default Countdown;
