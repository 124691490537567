// import CastFour from "Screens/CastFour";
import CastOne from "Screens/CastOne";
// import CastThree from "Screens/CastThree";
// import CastTwo from "Screens/CastTwo";
// import Home from "Screens/Home";
import RM from "Screens/RM";
import axios from "axios";
import { AppContextProvider } from "context/AppContext";
import React, { useEffect, useState } from "react";

import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";

function App() {
  const { pageNumber, id } = useParams();
  const [serverTime, setServerTime] = useState(null);

  const getServerTime = async () => {
    try {
      const response = await axios.get(
        // "http://localhost/Cowebcast-PHP/beta/api/apis/serverTime.php"
        "https://cowebcast.com/beta/api/apis/serverTime.php"
      );
      
      // const response = await axios.get(
      //   `https://cowebcast.com/beta/api/apis/serverTime.php`,
      //   {
      //     headers: {
      //       Accept: "application/json, text/plain, */*",
      //       "Accept-Encoding": "gzip, deflate, br, zstd",
      //       "Accept-Language": "en-US,en;q=0.9,es;q=0.8,it;q=0.7",
      //       Origin: "https://cowebcast.com",
      //       Priority: "u=1, i",
      //       Referer: "https://cowebcast.com/",
      //       "Sec-Ch-Ua":
      //         '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"',
      //       "Sec-Ch-Ua-Mobile": "?0",
      //       "Sec-Ch-Ua-Platform": '"Windows"',
      //       "Sec-Fetch-Dest": "empty",
      //       "Sec-Fetch-Mode": "cors",
      //       "Sec-Fetch-Site": "same-site",
      //       "User-Agent":
      //         "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36",
      //     },
      //   }
      // );
      if (response.status === 200) {
        const json = response.data;
        const st = new Date(json.dateTime);
        
        setServerTime(st);
        return st;
      }
    } catch (error) {
      console.error("Error fetching server time:", error);
    }
  };
  useEffect(() => {
    getServerTime();
  }, []);

  return (
    <>
      <BrowserRouter>
        {/* <AppContextProvider> */}
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          {/*  <Route path="/:id/:pageNumber" element={<CastTwo />} />*/}
          <Route path="/1/:pageNumber" element={<CastOne />} />
          {/* <Route path="/2/:pageNumber" element={<CastThree />} /> */}
          {/* <Route path="/cast/:pageNumber" element={<CastFour />} /> */}
          <Route path="/:rm/:id" element={<RM serverTime={serverTime} />} />
        </Routes>
        {/* </AppContextProvider> */}
      </BrowserRouter>
    </>
  );
}
export default App;
